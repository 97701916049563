const MESSAGE = {
  EN: {
    card_project_title: 'PROJECT',
    card_project_total_site: 'TOTAL SITE',
    card_project_total_camera: 'TOTAL CAMERA',
    card_project_btn_txt: 'Edit Project'
  },
  TH: {
    card_project_title: 'โปรเจกต์',
    card_project_total_site: 'จำนวนไซต์',
    card_project_total_camera: 'จำนวนกล้อง',
    card_project_btn_txt: 'แก้ไขโปรเจกต์'
  }
}
export default MESSAGE
