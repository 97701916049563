import styled from 'styled-components'
import siteBGImage from '.././../asset/images/project-card/site-bg.svg'
import cameraBGImage from '.././../asset/images/project-card/caemra-bg.svg'

export default styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  .project-card-screenshot-container {
    width: 150px;
    height: 100%;
    background: ${(props) => props.theme.greyDisabled};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .project-card-detail-title {
    color: rgba(31, 45, 57, 0.5);
    font-family: 'Prompt', sans-serif;
    font-size: 10px;
    font-weight: bold;
    line-height: 0.63;
    margin-bottom: 8px;
  }
  .project-card-detail {
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Prompt', sans-serif;
    font-size: 34px;
    font-weight: bold;
  }
  .section {
    &:after {
      content: '';
      width: 1px;
      height: calc(100% - 20px);
      position: absolute;
      top: 10px;
      right: 0px;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .project-card-name-container {
    width: 420px;
    padding: 20px 30px;
    position: relative;
  }
  .project-card-site-container {
    width: 210px;
    padding: 20px 30px;
    position: relative;
    background-image: url(${siteBGImage});
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 80% bottom;
  }
  .project-card-camera-container {
    width: 210px;
    padding: 20px 30px;
    position: relative;
    background-image: url(${cameraBGImage});
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 85% bottom;
  }
  .project-card-btn-container {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    .project-card-btn {
      width: 160px;
      font-size: 14px;
    }
  }
`
