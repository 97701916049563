import React from 'react'
import PropTypes from 'prop-types'

import SkeletonLoading from '../SkeletonLoading/Loadable'
import Button from '../../components/Button/Loadable'
import logoImg from '../../asset/images/sidebar/logo.svg'
import ProjectManagementCardStyled from './styledComponent'

import MESSAGE from './message'

class ProjectManagementCard extends React.PureComponent {
  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  getProjectScreenShot() {
    const imgSrc = this.props.screenshotImage ? this.props.screenshotImage : logoImg
    return (
      <div className="project-card-screenshot-container">
        <img src={imgSrc} alt="" />
      </div>
    )
  }

  getProjectNameSection() {
    return (
      <div className="project-card-name-container section">
        <div className="project-card-detail-title text-style">{this.getMessage('card_project_title')}</div>
        <div className="project-card-detail prevent-text-overflow text-style">{this.props.projectName}</div>
      </div>
    )
  }

  getProjectTotalSiteSection() {
    return (
      <div className="project-card-site-container section">
        <div className="project-card-detail-title">{this.getMessage('card_project_total_site')}</div>
        <div className="project-card-detail">{this.props.totalSites}</div>
      </div>
    )
  }

  getProjectTotalSiteCameraSection() {
    return (
      <div className="project-card-camera-container section">
        <div className="project-card-detail-title">{this.getMessage('card_project_total_camera')}</div>
        <div className="project-card-detail">{this.props.totalCameras}</div>
      </div>
    )
  }

  getProjectEditBtnSection() {
    return (
      <div className="project-card-btn-container">
        <Button
          className="project-card-btn"
          onClick={() => this.props.onClick(this.props.projectID)}
          text={this.getMessage('card_project_btn_txt')}
        />
      </div>
    )
  }

  getProjectManagementCard() {
    return (
      <>
        {this.getProjectScreenShot()}
        {this.getProjectNameSection()}
        {this.getProjectTotalSiteSection()}
        {this.getProjectTotalSiteCameraSection()}
        {this.getProjectEditBtnSection()}
      </>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getProjectManagementCard()
    return content
  }

  render() {
    return <ProjectManagementCardStyled>{this.getContent()}</ProjectManagementCardStyled>
  }
}

ProjectManagementCard.defaultProps = {
  language: 'EN',
  isLoading: false
}

ProjectManagementCard.propTypes = {
  projectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  screenshotImage: PropTypes.string,
  projectName: PropTypes.string.isRequired,
  totalSites: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  totalCameras: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  language: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
}

export default ProjectManagementCard
